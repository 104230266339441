const ClassName = {
	HIDDEN: '_hidden',
	EMPTY: '_empty',
	ACTIVE: '_active',
	SELECTED: '_selected',
};

class Combobox {
	constructor(elem) {
		this.initItem(elem);
	}
	initItem(elem) {
		this.elem = elem;

		if (!this.elem) {
			return;
		}

		this.list = this.elem.querySelector('[data-combobox-list]');
		this.input = this.elem.querySelector('[data-combobox-input]');
		this.options = Array.from(
			this.list.querySelectorAll('[data-combobox-option]'),
		);
		this.selected = this.list.querySelector(
			`[data-combobox-option="${this.input.value}"]`,
		);
		this.active = false;
		this.selected && this.setSelected(this.selected);

		this.list.addEventListener('click', ({ target }) => {
			if (target.closest('[data-combobox-option]')) {
				this.setSelected(target.closest('[data-combobox-option]'));
			}
		});
		this.input.addEventListener('focus', () => {
			this.open();
		});
		this.input.addEventListener('blur', () => {
			setTimeout(() => {
				this.close();
			}, 200);
		});
		this.input.addEventListener('input', (e) => {
			this.open();
			this.filterItems(e);
		});
		window.addEventListener('click', (evt) => {
			if (!this.elem.contains(evt.target) && this.active) {
				this.close();
			}
		});
	}
	setSelected(item) {
		if (this.selected) {
			this.selected.classList.remove(ClassName.SELECTED);
		}

		this.selected = item;
		this.value = this.selected.getAttribute('data-combobox-option');
		this.updateInput(this.selected.getAttribute('data-combobox-option'));
		this.selected.classList.add(ClassName.SELECTED);
	}
	updateInput(value) {
		this.input.value = value;
		this.input.dispatchEvent(new InputEvent('change'));
	}
	close() {
		if (!this.active) {
			return;
		}

		if (this.selected) {
			this.updateInput(this.selected.getAttribute('data-combobox-option'));
		}

		this.active = false;
		this.elem.classList.remove(ClassName.ACTIVE);
		this.clearFilter();
	}
	open() {
		this.active = true;
		this.elem.classList.add(ClassName.ACTIVE);
	}
	filterItems(e) {
		const value = e.target.value.toLowerCase();
		const filteredItems = this.options.filter((item) =>
			item.getAttribute('data-combobox-option').toLowerCase().includes(value),
		);
		const filteredIDs = filteredItems.map((item) => this.options.indexOf(item));

		let inputValue = e.target.value;
		inputValue = inputValue.replace(/[.*+?^${}()|[\]\\/]/g, '\\$&');
		const texts = document.querySelectorAll('.combobox__item');
		const pattern = new RegExp(`${inputValue}`, 'gi');

		texts.forEach((text) => {
			text.innerHTML = text.textContent.replace(
				pattern,
				(match) => `<span class="primary-blue">${match}</span>`,
			);
		});

		this.options.forEach((option, index) => {
			option.classList.toggle(ClassName.HIDDEN, !filteredIDs.includes(index));
		});

		const allHidden = this.options.every((item) =>
			item.classList.contains(ClassName.HIDDEN),
		);

		this.elem.classList.toggle(ClassName.EMPTY, allHidden);
	}
	clearFilter() {
		this.options.forEach((option) => {
			option.classList.remove(ClassName.HIDDEN);
		});
		this.elem.classList.remove(ClassName.EMPTY);
	}
}

document
	.querySelectorAll('[data-combobox]')
	.forEach((item) => new Combobox(item));
export default Combobox;
