import gsap from 'gsap';

class AboutPartners {
	constructor() {
		this.container = document.querySelectorAll('.about-partners');

		this.init();
	}

	init() {
		if (!this.container.length) {
			return;
		}

		this.container.forEach((el) => {
			this.initSlider(el);
		});
	}

	initSlider(el) {
		const wrap = el.querySelector('.about-partners__slider-wrap');
		const speed = Number(wrap.dataset.speed) || 4;
		const duration = Number(wrap.offsetWidth) / speed;
		[...Array(4)].forEach(() => {
			wrap.innerHTML += wrap.innerHTML;
		});
		gsap.to(wrap, {
			duration,
			xPercent: -100,
			repeat: -1,
			ease: 'none',
		});
	}
}

export default new AboutPartners();
