import gsap from 'gsap';
import Swiper from 'swiper';
import { Navigation, Controller } from 'swiper/modules';
import { ExpandMask } from './scene/ExpandMask';
import { EaseInQuad, Timeline } from '@anton.bobrov/vevet-init';
import PageScroll from '../../../assets/scripts/helpers/Scroll';

const HTML_CLASSLIST = document.documentElement.classList;

class Hero {
	sliderBlock;

	constructor() {
		this.container = document.querySelector('.hero');

		this.init();
	}

	init() {
		if (!this.container) {
			return;
		}

		this.initSlider();
		this.initMask();
	}
	initAnim() {
		const header = document.querySelector('.header');
		const headerLinkText = document.querySelectorAll('[data-header-anim]');
		const heroLogo = this.container.querySelector('.hero__logo');
		const slider = this.container.querySelector('.slider');
		const tl = gsap.timeline({
			delay: 1.3,
			onComplete: () => {
				gsap.set(headerLinkText, { clearProps: 'all' });
				header.classList.remove('_anim');
				HTML_CLASSLIST.remove('_mask-anim');
				if (this.mask) {
					this.mask.destroy();
				}
				PageScroll.enabled();
			},
		});

		header.classList.add('_anim');
		tl.set(headerLinkText, {
			transition: 'none',
			transformOrigin: 'right bottom',
		});
		tl.set(heroLogo, { transformOrigin: 'right bottom' });
		// link
		tl.from(headerLinkText, {
			yPercent: 120,
			rotate: -7,
			duration: 0.8,
			delay: 0.5,
			stagger: 0.1,
		});
		// logo
		tl.from(
			heroLogo,
			{
				yPercent: 120,
				rotate: -7,
				duration: 0.8,
			},
			'-=0.8',
		);
		// slider
		tl.from(
			slider,
			{
				yPercent: 100,
				duration: 0.8,
			},
			'-=0.8',
		);
	}
	initSlider() {
		const sliderBlock = this.container.querySelector('.slider__content');
		const imagesBlock = this.container.querySelector('.hero__bg');
		const prevBtn = this.container.querySelector('[data-slider-nav="prev"]');
		const nextBtn = this.container.querySelector('[data-slider-nav="next"]');

		if (sliderBlock && imagesBlock) {
			const textSlider = new Swiper(sliderBlock, {
				loop: true,
				allowTouchMove: false,
			});
			const imageSlider = new Swiper(imagesBlock, {
				modules: [Navigation, Controller],
				loop: true,
				allowTouchMove: false,
				navigation: {
					nextEl: nextBtn,
					prevEl: prevBtn,
				},
			});
			imageSlider.controller.control = textSlider;
		}
	}
	initMask() {
		PageScroll.disabled();
		const container = document.querySelector('.hero-mask');
		this.mask = new ExpandMask({
			container,
			onLoad: () => {
				container.classList.add('loaded');
				HTML_CLASSLIST.add('_mask-anim');
				setTimeout(() => {
					const tm = new Timeline({ duration: 2000, easing: EaseInQuad });

					tm.addCallback('progress', ({ easing }) => {
						this.mask.render(easing);
					});

					tm.play();

					this.initAnim();
				}, 1000);
			},
		});
	}
}

export default new Hero();
