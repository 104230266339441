import env from '../../assets/scripts/utils/env';

class OrderForm {
	constructor() {
		this.init();
	}

	init() {
		this.container = document.querySelector('.order-form');

		if (!this.container) {
			return;
		}

		if (env.isDesktop) {
			this._initGrabScroll();
		}
	}

	_initGrabScroll() {
		const scrollContainer = this.container.querySelector(
			'.order-form__row-wrapper',
		);

		if (!scrollContainer) {
			return;
		}

		let isDragging = false;
		let startX;
		let scrollLeft;

		scrollContainer.addEventListener('mousedown', (e) => {
			isDragging = true;
			startX = e.pageX - scrollContainer.offsetLeft;
			scrollLeft = scrollContainer.scrollLeft;
			scrollContainer.style.cursor = 'grabbing';
		});

		scrollContainer.addEventListener('mouseleave', () => {
			isDragging = false;
			scrollContainer.style.cursor = 'grab';
		});

		scrollContainer.addEventListener('mouseup', () => {
			isDragging = false;
			scrollContainer.style.cursor = 'grab';
		});

		scrollContainer.addEventListener('mousemove', (e) => {
			if (!isDragging) return;
			e.preventDefault();
			const x = e.pageX - scrollContainer.offsetLeft;
			const walk = (x - startX) * 2;
			scrollContainer.scrollLeft = scrollLeft - walk;
		});
	}
}

export default new OrderForm();
