import gsap from 'gsap';

class Everyday {
	constructor() {
		this.container = document.querySelector('.everyday');

		this.init();
	}

	init() {
		if (!this.container) {
			return;
		}

		const wrapper = this.container.querySelector('.everyday__items');
		const items = this.container.querySelectorAll('.everyday__item');
		const pinHeight = window.innerHeight * 4;
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: wrapper,
				start: 'center center',
				end: `+=${pinHeight}`,
				pin: true,
				scrub: 1,
			},
		});

		items.forEach((item, index) => {
			const name = item.querySelector('.everyday__name');
			const isToLeft = index === 2;
			if (index !== 0) {
				// tl.from(
				// 	item,
				// 	{
				// 		yPercent: -49,
				// 	},
				// 	'-=0.4',
				// );
				tl.from(
					name,
					{
						opacity: 0,
						yPercent: 15,
					},
					'-=0.25',
				);
			}
			tl.to(
				item,
				{
					rotate: isToLeft ? -20 : 20,
					delay: 0.5,
					duration: 1,
				},
				'-=0.3',
			);
			tl.to(
				name,
				{
					opacity: 0,
					xPercent: 10,
					duration: 0.3,
				},
				'-=0.4',
			);
		});
	}
}

export default new Everyday();
