import { lock, unlock } from 'tua-body-scroll-lock';
import Env from '../../assets/scripts/utils/env';

const ClassName = {
	ACTIVE: '_active',
};

class OrderCart {
	constructor() {
		this.init();
	}

	init() {
		this.container = document.querySelector('[data-order-cart]');
		this.header = document.querySelector('[data-order-cart-header]');
		this.toggle = document.querySelector('[data-order-cart-header-toggle]');

		if (!this.container) {
			return;
		}

		this.toggle.addEventListener('click', () => {
			this.container.classList.toggle(ClassName.ACTIVE);

			if (Env.isMobile) {
				this.container.classList.contains(ClassName.ACTIVE)
					? lock(this.container)
					: unlock(this.container);
			}
		});

		window.addEventListener('resize', () => {
			if (this.container.classList.contains(ClassName.ACTIVE)) {
				this.close();
			}
		});
		window.addEventListener('click', (evt) => {
			if (!this.container.contains(evt.target)) {
				this.close();
			}
		});
	}

	close() {
		this.container.classList.remove(ClassName.ACTIVE);
		Env.isMobile && unlock(this.container);
	}
}

export default new OrderCart();
