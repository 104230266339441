import { vevet } from '@anton.bobrov/vevet-init';
const HTML_CLASSLIST = document.documentElement.classList;

class KutyozhApp {
	constructor() {
		this.env = require('./utils/env').default;
		this.utils = require('./utils/utils').default;
		this.classes = {
			Signal: require('./classes/Signal').default,
		};
		this.components = {
			Hero: require('../../includes/main/hero/hero').default,
			CatalogSection:
				require('../../includes/main/catalog-section/catalog-section').default,
			AboutSection: require('../../includes/main/about-section/about-section')
				.default,
			Filters: require('../../includes/filters/filters').default,
			Sort: require('../../includes/sort/sort').default,
			ProductHero: require('../../includes/product-hero/product-hero').default,
			Header: require('../../components/header/header').default,
			Popups: require('../../components/popups/popups').default,
			Input: require('../../components/input/input').default,
			Combobox: require('../../components/combobox/combobox').default,
			InputButton: require('../../components/input-button/input-button')
				.default,
			SignInForm: require('../../components/sign-in-form/sign-in-form').default,
			ProfileNav: require('../../includes/profile-nav/profile-nav').default,
			// AddressCard: require('../../includes/address-card/address-card').default,
			Profile: require('../../includes/profile/profile').default,
			CategorySection:
				require('../../includes/main/category-section/category-section')
					.default,
			Ticker: require('../../components/ticker/ticker').default,
			Opportunities: require('../../includes/about/opportunities/opportunities')
				.default,
			Everyday: require('../../includes/about/everyday/everyday').default,
			AboutPartners:
				require('../../includes/about/about-partners/about-partners').default,
			OrderCart: require('../../includes/order-cart/order-cart').default,
			Cookies: require('../../components/cookies/cookies').default,
			OrderForm: require('../../includes/order-form/order-form').default,
		};
		this.helpers = {
			Scroll: require('./helpers/Scroll').default,
		};
		this.modules = {
			SliderMobile: require('./modules/SliderMobile').default,
			Tabs: require('./modules/Tabs').default,
			Parallax: require('./modules/Parallax').default,
		};

		const resize = () => {
			document.documentElement.style.setProperty(
				'--vh-memo',
				`${vevet.viewport.vh}px`,
			);
		};

		vevet.viewport.add(vevet.isMobile ? 'width' : 'any', () => resize());

		vevet
			.onPageLoad()
			.then(() => resize())
			.catch(() => {});

		resize();

		window.addEventListener('load', () => {
			HTML_CLASSLIST.remove('_loading');

			const hash = window.location.hash;
			if (hash) {
				const popupName = hash.substring(1);
				const popup = this.components.Popups.getPopup(popupName);

				if (popup) {
					this.components.Popups.open(popupName);
				}
			}

			document.documentElement.style.setProperty(
				'--scrollbar-width',
				`${window.innerWidth - document.body.clientWidth}px`,
			);
		});
	}
}

window.KutyozhApp = new KutyozhApp();
