class Input {
	constructor(container) {
		this.init(container);
	}

	init(container) {
		this.container = container;
		this.input = this.container.querySelector('input, textarea');

		if (!this.input) {
			return;
		}

		this.type = this.container.getAttribute('data-input');

		this.container.classList.toggle('_has-value', this.input.value.length);
		this.input.addEventListener('change', () => {
			this.container.classList.toggle('_has-value', this.input.value.length);
		});
		this.input.addEventListener('input', () => {
			this.container.classList.toggle('_has-value', this.input.value.length);

			if (this.type === 'tel') {
				this.input.value = this.input.value.replace(/[^\d\+\-]/g, '');
			}
		});
	}
}

document.querySelectorAll('[data-input]').forEach((input) => new Input(input));

export default Input;
