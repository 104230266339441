import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import env from '../utils/env';

const HTML_CLASSLIST = document.documentElement.classList;

const ClassName = {
	NO_SCROLL: '_no-scroll',
};

class Scroll {
	disabled() {
		if (env.isIOS) {
			document.body.style.overflow = 'hidden';
			document.documentElement.style.overflow = 'hidden';
		} else {
			disableBodyScroll(document.body);
		}
		HTML_CLASSLIST.add(ClassName.NO_SCROLL);
	}
	enabled() {
		if (env.isIOS) {
			document.body.style.overflow = '';
			document.documentElement.style.overflow = '';
		} else {
			enableBodyScroll(document.body);
		}

		HTML_CLASSLIST.remove(ClassName.NO_SCROLL);
		document.documentElement.style.setProperty(
			'--scrollbar-width',
			`${window.innerWidth - document.body.clientWidth}px`,
		);
	}
}

export default new Scroll();
