import { clampScope, Ctx2D, vevet } from '@anton.bobrov/vevet-init';
import { IPrerender, IProps } from './types';
import { PRERENDER_COUNT } from '../settings';

export class Canvas {
	private _ctx2D: Ctx2D;

	private _prerenders: IPrerender[] = [];

	private _prevProgress = 0;

	constructor(private _props: IProps) {
		this._ctx2D = new Ctx2D({
			container: _props.container,
			shouldAppend: true,
			hasResize: true,
			hasInitialResize: true,
			dpr: Math.min(vevet.viewport.lowerDesktopDpr, 2.5),
		});

		this._ctx2D.addCallback('resize', () => {
			this._createPrerenders();
			this.render(this._prevProgress);
		});

		this._createPrerenders();
		this.render(0);
	}

	/** Create prerender instances */
	private _createPrerenders() {
		const step = 1 / PRERENDER_COUNT;

		// destroy previous prerenders
		this._prerenders.forEach(({ prerender }) => prerender.destroy());
		this._prerenders = [];

		// create new prerenders
		for (let index = 0; index < PRERENDER_COUNT; index += 1) {
			const progress = step * index;

			const prerender = this._createSinglePrerender(progress);

			this._prerenders.push({
				prerender,
				progressFrom: progress,
				progressTo: progress + step,
			});
		}
	}

	/** Create a single prerender instance */
	private _createSinglePrerender(progress: number) {
		const { maxScale, size } = this;
		const { image } = this._props;
		const { width, height } = this._ctx2D;

		const ctx2D = new Ctx2D({
			container: false,
			hasResize: false,
			hasInitialResize: false,
			dpr: 1,
			width,
			height,
		});

		ctx2D.resize();

		const scale = 1 + (maxScale - 1) * progress;
		const maskWidth = size.width * scale;
		const maskHeight = size.height * scale;

		const shiftX = size.shiftX * maxScale * progress;
		const shiftY = size.shiftY * maxScale * progress;

		const left = (width - maskWidth) / 2 - shiftX;
		const top = (height - maskHeight) / 2 - shiftY;

		// ctx

		ctx2D.ctx.clearRect(0, 0, width, height);
		ctx2D.ctx.drawImage(image, left, top, maskWidth, maskHeight);

		return ctx2D;
	}

	/** Get mask sizes */
	private get size() {
		const { image } = this._props;

		const ratio = image.height / image.width;

		const width = this._ctx2D.width * 0.698;
		const height = width * ratio;

		const targetWidth = 0.08648111332 * width;
		const targetHeight = 0.1942975916 * height;

		const targetLeft = 0.56660039761431 * width + targetWidth / 2;
		const targetTop = 0.4247435723 * height + targetHeight / 2;

		const shiftX = targetLeft - width / 2;
		const shiftY = targetTop - height / 2;

		return {
			width,
			height,
			targetLeft,
			targetWidth,
			targetTop,
			targetHeight,
			shiftX,
			shiftY,
		};
	}

	/** Get mask scale value */
	private get maxScale() {
		const { width, height } = this._ctx2D;
		const { size } = this;

		const xScale = width / size.targetWidth;
		const yScale = height / size.targetHeight;
		const scale = Math.max(xScale, yScale);

		return scale;
	}

	/** Render the scene */
	public render(progress: number) {
		this._prevProgress = progress;

		const prerenderIndex = this._prerenders.findIndex(
			({ progressFrom, progressTo }) =>
				progress >= progressFrom && progress <= progressTo,
		);

		const prerenderInstance = this._prerenders[prerenderIndex];
		if (!prerenderInstance) {
			return;
		}

		const { maxScale, size } = this;
		const { prerender, progressFrom, progressTo } = prerenderInstance;

		const currentProgress = clampScope(progress, [progressFrom, progressTo]);

		const scaleFrom = 1 + (maxScale - 1) * progressFrom;
		const scaleTo = 1 + (maxScale - 1) * progressTo;
		const currentScale = 1 + (scaleTo / scaleFrom - 1) * currentProgress;

		const shiftScale = 1 + progress * (maxScale - 1);

		const shiftXFrom = size.shiftX * maxScale * progressFrom;
		const shiftXTo = size.shiftX * maxScale * progressTo;
		const shiftX = ((shiftXTo - shiftXFrom) * currentProgress) / shiftScale;

		const shiftYFrom = size.shiftY * maxScale * progressFrom;
		const shiftYTo = size.shiftY * maxScale * progressTo;
		const shiftY = ((shiftYTo - shiftYFrom) * currentProgress) / shiftScale;

		this._ctx2D.render(({ ctx, width, height }) => {
			ctx.save();
			ctx.clearRect(0, 0, width, height);

			ctx.imageSmoothingEnabled = true;
			ctx.imageSmoothingQuality = 'high';

			ctx.beginPath();

			ctx.fillStyle = 'white';
			ctx.fillRect(0, 0, width, height);

			ctx.translate(width / 2, height / 2);
			ctx.scale(currentScale, currentScale);
			ctx.translate(-width / 2, -height / 2);

			ctx.globalCompositeOperation = 'destination-out';
			ctx.drawImage(prerender.canvas, -shiftX, -shiftY);

			ctx.closePath();
			ctx.restore();
		});
	}

	/** Destroy the scene */
	public destroy() {
		this._ctx2D.destroy();

		this._prerenders.forEach(({ prerender }) => prerender.destroy());
	}
}
