import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import gsap from 'gsap';

const HTML_CLASSNAME = document.documentElement.classList;
const ClassName = {
	ACTIVE: '_active',
};

class ProfileNav {
	constructor() {
		this.init();
	}

	init() {
		this.container = document.querySelector('.profile-nav');

		if (!this.container) {
			return;
		}

		this.list = this.container.querySelector('.profile-nav__list');
		this.links = this.container.querySelectorAll('.profile-nav__link');
		this.toggleButton = this.container.querySelector('.profile-nav__toggle');

		this.links.forEach((link) => {
			link.addEventListener('click', () => this.close());
		});

		this.toggleButton.addEventListener('click', () =>
			this.isOpened() ? this.close() : this.open(),
		);

		window.addEventListener(
			'keydown',
			({ key }) => key === 'Escape' && this.close(),
		);

		window.addEventListener('click', (evt) => {
			if (
				!evt.target.closest('.profile-nav__wrapper') &&
				this.container.classList.contains(ClassName.ACTIVE)
			) {
				this.close();
			}
		});

		const mm = window.matchMedia('(min-width: 769px)');

		if (mm.matches) {
			this.destroy();
		} else {
			this.toggleButton.removeAttribute('disabled');
		}

		window
			.matchMedia('(min-width: 769px)')
			.addEventListener('change', (evt) => {
				if (evt.matches) {
					this.destroy();
				} else {
					this.toggleButton.removeAttribute('disabled');
				}
			});
	}
	open() {
		this.container.classList.add(ClassName.ACTIVE);
		disableBodyScroll(this.list);
		gsap.to(this.list, {
			duration: 0.3,
			height: 'auto',
		});
	}
	close() {
		if (!this.isOpened()) return;
		this.container.classList.remove(ClassName.ACTIVE);
		enableBodyScroll(this.list);
		gsap.to(this.list, {
			duration: 0.3,
			height: 0,
		});
	}
	destroy() {
		gsap.set(this.list, { clearProps: 'all' });
		this.container.classList.remove(ClassName.ACTIVE);
		this.toggleButton.setAttribute('disabled', true);
	}
	isOpened() {
		return this.container.classList.contains(ClassName.ACTIVE);
	}
}

export default new ProfileNav();
