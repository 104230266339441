interface IHandler {
	handler: Function;
	context?: any;
}

class Signal {
	handlers: IHandler[];
	constructor() {
		this.handlers = [];
	}

	_throwError(): never {
		throw new TypeError('Signal handler must be a function!');
	}

	add(handler: Function, context?: any): Function | null {
		if (typeof handler !== 'function') {
			this._throwError();
		}
		this.handlers.push({ handler, context });
		return handler;
	}

	remove(handler: Function): Function | void {
		if (typeof handler !== 'function') {
			this._throwError();
		}

		const totalHandlers = this.handlers.length;
		for (let k = 0; k < totalHandlers; k++) {
			if (handler === this.handlers[k].handler) {
				this.handlers.splice(k, 1);
				return handler;
			}
		}
	}

	call(...args: any[]): void {
		const totalHandlers = this.handlers.length;
		for (let k = 0; k < totalHandlers; k++) {
			const handlerData = this.handlers[k];
			handlerData.handler.apply(handlerData.context || null, args);
		}
	}

	delayedCall(delay: number = 16, ...args: any[]): void {
		setTimeout(() => {
			this.call(...args);
		}, delay);
	}
}

export default Signal;
