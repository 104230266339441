import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

class AboutSection {
	aboutBlock: HTMLElement | null;

	constructor() {
		this.aboutBlock = document.querySelector('[data-about-section]');

		this.init();
	}

	init() {
		if (!this.aboutBlock) {
			return;
		}
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: this.aboutBlock,
				start: 'top bottom',
				end: 'bottom top',
				scrub: 1,
			},
		});
		gsap.utils.toArray('[data-about-img]').forEach((layer: any) => {
			const depth: number = Number(layer.dataset.depth || '0');
			const movement = -(layer.offsetHeight * depth);
			tl.fromTo(layer, { y: -movement }, { y: movement, ease: 'none' }, 0);
		});
	}
}

export default new AboutSection();
