class Sort {
	sortButtonOpen: HTMLButtonElement | null;
	sort: HTMLDivElement | null;
	sortItems: NodeListOf<HTMLButtonElement> | null;
	filters: HTMLDivElement | null;

	constructor() {
		this.sortButtonOpen = document.querySelector('[data-sort-open]');
		this.filters = document.querySelector('.controls__filters');
		this.sort = document.querySelector('[data-sort]');
		this.sortItems = this.sort
			? this.sort.querySelectorAll('.sort-dropdown__button')
			: null;

		this.init();
	}

	init() {
		this.sortButtonOpen?.addEventListener('click', () => {
			this.sortButtonOpen?.classList.toggle('_active');
			this.sort?.classList.toggle('_open');
			this.filters?.classList.toggle('_hide');
		});

		this.sortItems?.forEach((item) => {
			item.addEventListener('click', () => {
				this.close();
			});
		});

		window.addEventListener('keydown', (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				this.close();
			}
		});

		window.addEventListener('click', (event: MouseEvent) => {
			if (
				!(event.target as HTMLElement).classList.contains('sort__btn') &&
				this.sortButtonOpen?.classList.contains('_active')
			) {
				this.close();
			}
		});
	}
	close() {
		this.sortButtonOpen?.classList.remove('_active');
		this.sort?.classList.remove('_open');
		this.filters?.classList.remove('_hide');
	}
}

export default new Sort();
