const ClassName = {
	HIDDEN: '_hidden',
};

class Cookies {
	constructor() {
		this.init();
	}

	init() {
		this.container = document.querySelector('[data-cookies]');

		if (!this.container) {
			return;
		}

		const cookiesName = 'cookies-info-shown';
		const closeButton = this.container.querySelector('.cookies__close');
		this.hasCookie = this.getCookie(cookiesName);

		!this.hasCookie &&
			setTimeout(() => {
				this.container.classList.remove(ClassName.HIDDEN);
			}, 2000);

		closeButton &&
			closeButton.addEventListener('click', (evt) => {
				evt.preventDefault();
				this.setCookie(cookiesName, 'closed');
				this.container.classList.add(ClassName.HIDDEN);
			});
	}

	getCookie(name) {
		const value = ' ' + document.cookie;
		const parts = value.split(' ' + name + '=');
		return parts.length < 2 ? undefined : parts.pop().split(';').shift();
	}

	setCookie(name, value, expiryDays, domain, path, secure) {
		const exdate = new Date();
		exdate.setHours(
			exdate.getHours() +
				(typeof expiryDays !== 'number' ? 365 : expiryDays) * 24,
		);
		document.cookie =
			name +
			'=' +
			value +
			';expires=' +
			exdate.toUTCString() +
			';path=' +
			(path || '/') +
			(domain ? ';domain=' + domain : '') +
			(secure ? ';secure' : '');
	}
}

export default new Cookies();
