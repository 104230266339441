import gsap from 'gsap';

class Opportunities {
	constructor() {
		this.containers = document.querySelectorAll('.opportunities');

		this.init();
	}

	init() {
		if (!this.containers.length) {
			return;
		}

		this.containers.forEach((container) => {
			this.initFillingText(container);
		});
	}
	initFillingText(el) {
		const origText = el.querySelector('.opportunities__text._orig');
		const hiddenText = el.querySelector('.opportunities__text._hidden');
		const origTextParagraphs = origText.querySelectorAll('p');
		const hiddenTextParagraphs = hiddenText.querySelectorAll('p');
		origTextParagraphs.forEach((p) => {
			this.splitText(p);
		});
		hiddenTextParagraphs.forEach((p) => {
			this.splitText(p);
		});
		const tl = gsap
			.timeline({
				scrollTrigger: {
					trigger: el,
					scrub: 1,
					start: 'top 70%',
					end: 'center center',
				},
			})
			.set(
				hiddenText.querySelectorAll('.char'),
				{
					autoAlpha: 1,
					stagger: distributeByPosition({
						amount: 2,
					}),
				},
				0.1,
			)
			.set(
				origText.querySelectorAll('.char'),
				{
					autoAlpha: 0,
					stagger: distributeByPosition({
						amount: 2,
					}),
				},
				0.1,
			);

		function distributeByPosition(vars) {
			var ease = vars.ease && gsap.parseEase(vars.ease),
				from = vars.from || 0,
				base = vars.base || 0,
				axis = vars.axis,
				ratio = { center: 0.5, end: 1, edges: 0.5 }[from] || 0,
				distances;
			return function (i, target, a) {
				var l = a.length,
					originX,
					originY,
					x,
					y,
					d,
					j,
					minX,
					maxX,
					minY,
					maxY,
					positions;
				if (!distances) {
					distances = [];
					minX = minY = Infinity;
					maxX = maxY = -minX;
					positions = [];
					for (j = 0; j < l; j++) {
						d = a[j].getBoundingClientRect();
						x = (d.left + d.right) / 2; //based on the center of each element
						y = (d.top + d.bottom) / 2;
						if (x < minX) {
							minX = x;
						}
						if (x > maxX) {
							maxX = x;
						}
						if (y < minY) {
							minY = y;
						}
						if (y > maxY) {
							maxY = y;
						}
						positions[j] = { x: x, y: y };
					}
					originX = isNaN(from)
						? minX + (maxX - minX) * ratio
						: positions[from].x || 0;
					originY = isNaN(from)
						? minY + (maxY - minY) * ratio
						: positions[from].y || 0;
					maxX = 0;
					minX = Infinity;
					for (j = 0; j < l; j++) {
						x = positions[j].x - originX;
						y = originY - positions[j].y;
						distances[j] = d = !axis
							? Math.sqrt(x * x + y * y)
							: Math.abs(axis === 'y' ? y : x);
						if (d > maxX) {
							maxX = d;
						}
						if (d < minX) {
							minX = d;
						}
					}
					distances.max = maxX - minX;
					distances.min = minX;
					distances.v = l =
						(vars.amount || vars.each * l || 0) * (from === 'edges' ? -1 : 1);
					distances.b = l < 0 ? base - l : base;
				}
				l = (distances[i] - distances.min) / distances.max;
				return distances.b + (ease ? ease(l) : l) * distances.v;
			};
		}
	}
	splitText(textBlock) {
		const text = textBlock.innerHTML;
		const chars = text.split(/(?=[^>]*(?:<|$))/);
		textBlock.innerHTML = '';
		chars.forEach((char) => {
			const span = document.createElement('span');
			span.classList.add('char');
			span.innerHTML = char;
			textBlock.appendChild(span);
		});
	}
}

export default new Opportunities();
