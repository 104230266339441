import Swiper from 'swiper';
import { Autoplay, Pagination } from 'swiper/modules';
declare type Swiper = typeof Swiper;

class CatalogSection {
	catalogBlocks: NodeListOf<Element>;

	constructor() {
		this.catalogBlocks = document.querySelectorAll('[data-catalog]');

		this.init();
	}

	init() {
		if (!this.catalogBlocks.length) {
			return;
		}
		this.catalogBlocks.forEach((catalogBlock) => {
			this.initCatalogSectionSlider(catalogBlock);
		});
	}

	initCatalogSectionSlider(catalogBlock: Element) {
		const catalogSectionBlock = catalogBlock.querySelector(
			'[data-catalog-slider]',
		) as HTMLElement;
		if (catalogSectionBlock) {
			const pagination = catalogBlock.querySelector(
				'[data-catalog-pagination]',
			) as HTMLElement;
			let slider: Swiper | null = null;
			const updateSlider = () => {
				if (window.innerWidth < 768) {
					if (!slider) {
						slider = new Swiper(catalogSectionBlock, {
							modules: [Pagination, Autoplay],
							lazyPreloadPrevNext: 2,
							loop: true,
							speed: 500,
							pagination: {
								el: pagination,
							},
						});
					}
				} else {
					if (slider) {
						(slider as Swiper).destroy();
						slider = null;
					}
				}
			};
			updateSlider();
			window.addEventListener('resize', updateSlider);
		}
	}
}

export default new CatalogSection();
