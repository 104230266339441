import gsap from 'gsap';

class Ticker {
	constructor() {
		this.ticker = document.querySelectorAll('.ticker');

		this.init();
	}

	init() {
		if (!this.ticker.length) {
			return;
		}

		this.ticker.forEach((el) => {
			this.initTicker(el);
		});
	}

	initTicker(el) {
		const text = el.querySelector('.ticker__text');
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: el,
				start: 'top bottom',
				end: 'top 10%',
				scrub: 2,
			},
		});
		tl.fromTo(
			text,
			{
				x: el.offsetWidth,
			},
			{
				x: el.offsetWidth - text.offsetWidth,
				ease: 'none',
			},
		);
	}
}

export default new Ticker();
