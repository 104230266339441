import Utils from '../../assets/scripts/utils/utils';

const ClassName = {
	SMS_CODE: '_sms-code',
	SEND_CODE: '_send-code',
};

class SignInForm {
	constructor() {
		this.init();
	}

	init() {
		this.container = document.querySelector('[data-sign-in-form]');

		if (!this.container) {
			return;
		}
	}
	showTimer(value) {
		const form = this.container;
		const smsTimeout = form.querySelector('[data-sms-timeout]');
		const smsTimeoutValue = form.querySelector('[data-sms-timeout-value]');
		const smsTimeoutUnit = form.querySelector('[data-sms-timeout-unit]');
		const smsButton = form.querySelector('[data-sms-timeout-start]');

		if (
			!smsTimeout ||
			!smsTimeoutValue ||
			!smsTimeoutUnit ||
			smsTimeout.running
		) {
			return;
		}

		const units = smsTimeoutUnit
			.getAttribute('data-sms-timeout-unit')
			.split(',');
		const updateTimerText = (val) => {
			smsTimeoutValue.textContent = val;
			smsTimeoutUnit.textContent = Utils.declOfNum(val, units);
		};
		let counter = value;

		form.classList.remove(ClassName.SMS_CODE, ClassName.SEND_CODE);
		smsTimeout.running = true;

		updateTimerText(counter);

		const timer = setInterval(() => {
			counter--;
			updateTimerText(counter);

			if (smsTimeoutValue.textContent <= 0) {
				clearInterval(timer);
				form.classList.add(ClassName.SEND_CODE);
				smsTimeout.running = false;
			}
		}, 1000);

		form.classList.add(ClassName.SMS_CODE);

		smsButton.addEventListener('click', () => this.showTimer(value), {
			once: true,
		});
	}
}

export default new SignInForm();
