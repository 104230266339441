const ClassName = {
	success: '_success',
	error: '_error',
};

const validEmailRegex =
	/^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

class InputButton {
	constructor(container) {
		this.init(container);
	}

	init(container) {
		this.container = container;
		this.input = this.container.querySelector('input, textarea');
		this.btn = this.container.querySelector('.input-button__btn');

		if (!this.input) {
			return;
		}
		const updateInput = () => {
			this.container.classList.remove(ClassName.error);
			if (this.input.value.length) {
				this.btn.disabled = false;
			} else {
				this.btn.disabled = true;
			}
		};
		this.input.addEventListener('change', updateInput);
		this.input.addEventListener('input', updateInput);
		this.btn.addEventListener('click', () => {
			if (this.input.type === 'email') {
				if (this.input.value.match(validEmailRegex)) {
					this.container.classList.add('_success');
				} else {
					this.container.classList.add('_error');
				}
				return;
			}
		});
	}
}

document
	.querySelectorAll('[data-input-button]')
	.forEach((input) => new InputButton(input));

export default InputButton;
