import { Timeline, vevet } from '@anton.bobrov/vevet-init';
import { Canvas } from './Canvas';
import { maskSrc } from '../maskSrc';
import { IProps } from './types';

export class ExpandMask {
	private _isDestroyed = false;

	private _canvas?: Canvas;

	private _timeline?: Timeline;

	constructor({ container, onLoad }: IProps) {
		window.vevetApp = vevet;

		const mask = new Image();

		mask.addEventListener('load', () => {
			if (this._isDestroyed) {
				return;
			}

			this._canvas = new Canvas({ container, image: mask });

			onLoad();
		});

		mask.src = maskSrc;
	}

	public render(progress: number) {
		this._canvas?.render(progress);
	}

	public destroy() {
		this._isDestroyed = true;

		this._canvas?.destroy();
		this._timeline?.destroy();
	}
}
