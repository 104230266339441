import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';

const BREAKPOINT = 575;
declare type Swiper = typeof Swiper;

class SliderMobile {
	element: HTMLElement;
	swiper: Swiper;
	initSlider: boolean;
	mobile: MediaQueryList;
	desktop: MediaQueryList;

	constructor(el: HTMLElement) {
		this.element = el;
		this.swiper = Swiper;
		this.initSlider = false;
		this.mobile = window.matchMedia(`(max-width: ${BREAKPOINT}px)`);
		this.desktop = window.matchMedia(`(min-width: ${BREAKPOINT}px)`);

		window.addEventListener('load', () => this.init());
		window.addEventListener('resize', () => this.init());
	}

	init() {
		if (this.mobile.matches) {
			if (!this.initSlider) {
				this.initSlider = true;
				this.swiper = new Swiper(this.element, {
					slidesPerView: 1,
					spaceBetween: 10,
					modules: [Pagination],
					pagination: {
						el: '.swiper-pagination',
					},
				});
			}
		} else if (this.desktop.matches) {
			if (this.initSlider) {
				this.swiper.destroy();
				this.initSlider = false;
			}
		}
	}
}

document.addEventListener('DOMContentLoaded', () => {
	document
		.querySelectorAll('[data-slider-mobile]')
		.forEach((slider) => new SliderMobile(slider as HTMLElement));
});

export default SliderMobile;
