import gsap from 'gsap';

class Parallax {
	constructor(container) {
		if (!container) {
			return;
		}

		this.container = container;

		this.init(this.container);
	}

	init(container) {
		const elements = container.querySelectorAll('[data-parallax-speed]');
		if (!elements.length) {
			return;
		}
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: container,
				start: 'top bottom',
				end: 'bottom top',
				scrub: 1,
			},
		});
		elements.forEach((layer) => {
			const depth = Number(layer.dataset.parallaxSpeed || '0');
			const movement = -(layer.offsetHeight * depth);
			tl.fromTo(layer, { y: -movement }, { y: movement, ease: 'none' }, 0);
		});
	}
}

document
	.querySelectorAll('[data-parallax-container]')
	.forEach((el) => new Parallax(el));
export default Parallax;
