import gsap from 'gsap';

const ClassName = {
	EDIT: '_edit',
	ACTIVE: '_active',
};

class Profile {
	constructor() {
		this.init();
	}

	init() {
		this.container = document.querySelector('[data-profile]');

		if (!this.container) {
			return;
		}

		this.initInfo();
		this.initOrders();
	}

	initInfo() {
		const container = this.container.querySelector('[data-profile-info]');
		if (!container) return;

		const form = container.querySelector('.profile-info__form');
		const inputs = form.querySelectorAll('input, textarea');

		inputs.forEach((input) => {
			input.addEventListener('input', () => {
				container.classList.add(ClassName.EDIT);
			});
		});
	}
	initOrders() {
		const container = this.container.querySelector('[data-profile-orders]');
		if (!container) return;

		const items = container.querySelectorAll('.profile-orders__item');

		items.forEach((item) => {
			const toggle = item.querySelector('.profile-orders__item-toggle');

			toggle.addEventListener('click', () => {
				const startHeight = getComputedStyle(item).getPropertyValue('--height');

				gsap.to(item, {
					duration: 0.3,
					height: item.classList.contains(ClassName.ACTIVE)
						? startHeight
						: 'auto',
				});

				item.classList.toggle(ClassName.ACTIVE);

				toggle.blur();
			});
		});
	}
}

export default new Profile();
