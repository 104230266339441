import SimpleBar from 'simplebar';
import ResizeObserver from 'resize-observer-polyfill';
import Scroll from '../../assets/scripts/helpers/Scroll';

const CLASS_NAME = {
	ACTIVE: '_active',
	OPEN: '_open',
	HIDE: '_hide',
};

class Filters {
	buttonOpen: HTMLButtonElement | null = null;
	filters: HTMLDivElement | null;
	sort: HTMLDivElement | null = null;
	constructor() {
		this.filters = document.querySelector('[data-filters]');

		if (this.filters) {
			this.buttonOpen = this.filters.querySelector('[data-filters-open]');
			this.sort = document.querySelector('.controls__sort');

			window.ResizeObserver = ResizeObserver;
			new SimpleBar(document.querySelector('.filters__scroll'));
		}

		this.init();
	}

	init() {
		this.setupOpenFilters();

		window.addEventListener('keydown', (event) => {
			if (event.key === 'Escape') {
				this.close();
			}
		});

		window.addEventListener('click', (event) => {
			if (event.target === this.filters) {
				this.close();
			}
		});
	}
	choiceFilter() {}
	setupOpenFilters() {
		this.buttonOpen?.addEventListener('click', () => {
			this.buttonOpen?.classList.toggle(CLASS_NAME.ACTIVE);
			this.filters?.classList.toggle(CLASS_NAME.OPEN);
			if (this.filters?.classList.contains(CLASS_NAME.OPEN)) {
				this.open();
			} else {
				this.close();
			}
		});
	}
	open() {
		Scroll.disabled();
		this.filters?.classList.add(CLASS_NAME.OPEN);
		this.buttonOpen?.classList.add(CLASS_NAME.ACTIVE);
		this.sort?.classList.add(CLASS_NAME.HIDE);
	}
	close() {
		Scroll.enabled();
		this.filters?.classList.remove(CLASS_NAME.OPEN);
		this.buttonOpen?.classList.remove(CLASS_NAME.ACTIVE);
		this.sort?.classList.remove(CLASS_NAME.HIDE);
	}
}

export default new Filters();
