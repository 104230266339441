import gsap from 'gsap';

class CategorySection {
	constructor() {
		this.init();
	}

	init() {
		this.container = document.querySelector('.category-section');

		if (!this.container) {
			return;
		}

		const items = this.container.querySelectorAll('.category-section__link');
		const images = this.container.querySelectorAll(
			'.category-section__picture',
		);

		const mousePos = { x: 0, y: 0 };
		const itemsPos = [];
		const updateItemsPos = () => {
			items.forEach((item, index) => {
				const bounding_rect = item.getBoundingClientRect();
				itemsPos[index] = {
					top: bounding_rect.top,
					right: bounding_rect.right,
					bottom: bounding_rect.bottom,
					left: bounding_rect.left,
				};
			});
		};
		const updateImagesPos = (x, y) => {
			gsap.to(images, {
				top: y,
				left: x,
			});
		};
		const hideImage = (image) => {
			gsap.set(image, {
				opacity: 0,
			});
		};
		const showImage = (image) => {
			gsap.set(image, {
				opacity: 1,
			});
		};
		updateItemsPos();
		const mousemoveHandler = (e) => {
			mousePos.x = e.clientX;
			mousePos.y = e.clientY;
			updateImagesPos(mousePos.x, mousePos.y);
		};
		const scrollHandler = () => {
			updateItemsPos();
			updateImagesPos(mousePos.x, mousePos.y);
			items.forEach((item, index) => {
				const image = item.querySelector('.category-section__picture');
				if (
					mousePos.x > itemsPos[index].left &&
					mousePos.x < itemsPos[index].right &&
					mousePos.y > itemsPos[index].top &&
					mousePos.y < itemsPos[index].bottom
				) {
					showImage(image);
				} else {
					hideImage(image);
				}
			});
		};
		document?.addEventListener('mousemove', mousemoveHandler);
		document.addEventListener('scroll', scrollHandler);
		items.forEach((item) => {
			const image = item.querySelector('.category-section__picture');
			item.addEventListener('mouseenter', () => {
				showImage(image);
			});
			item.addEventListener('mouseleave', () => {
				hideImage(image);
			});
		});
	}
}

export default new CategorySection();
