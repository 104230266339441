import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import gsap from 'gsap';

const HTML_CLASSLIST = document.documentElement.classList;

class Header {
	constructor() {
		this.header = document.querySelector('[data-header]');
		this.menuBtn = this.header?.querySelector('[data-menu-btn]');
		this.menu = this.header?.querySelector('[data-menu]');
		this.menuWrap = this.header?.querySelector('[data-menu-wrap]');
		this.isMenuOpen = false;
		this.menuTl = null;

		this.init();
	}

	init() {
		if (!this.header) {
			return;
		}

		const header = this.header;
		let lastScrollTop = 0;

		window.addEventListener('scroll', function () {
			const scrollTop = window.scrollY;

			if (scrollTop > lastScrollTop) {
				// scrolling down
				if (scrollTop > 100) {
					header.classList.remove('_scrolled');
					HTML_CLASSLIST.remove('_header-scrolled');
					header.classList.add('_fixed');
					HTML_CLASSLIST.add('_header-fixed');
					this.setTimeout(() => {
						header.classList.add('_transition');
					});
				}
			} else {
				// scrolling up
				if (scrollTop > 100) {
					header.classList.add('_scrolled');
					HTML_CLASSLIST.add('_header-scrolled');
				}
			}
			if (scrollTop === 0) {
				header.classList.remove('_fixed');
				HTML_CLASSLIST.remove('_header-fixed');
				header.classList.remove('_transition');
			}

			lastScrollTop = scrollTop;
		});
		this.addMenuHandler();
		window.increaseHeaderCartItems = this.increaseCartItems;
	}
	addMenuHandler() {
		if (this.menuWrap && this.menu) {
			this.menuTl = gsap.timeline();
			this.menuTl.set(this.menu, { display: 'block' });
			this.menuTl.from(this.menuWrap, {
				xPercent: 100,
			});
			this.menuTl.pause();
		}

		this.menuBtn?.addEventListener('click', () => {
			this.toggleMenu();
		});
	}
	toggleMenu() {
		if (this.isMenuOpen) {
			this.closeMenu();
		} else {
			this.openMenu();
		}
	}
	openMenu() {
		this.header?.classList.add('_menu');
		setTimeout(() => {
			this.menuWrap.scroll({ top: 0 });
		}, 10);
		this.menuTl?.play();
		if (this.menuWrap) {
			disableBodyScroll(this.menuWrap);
		}
		this.isMenuOpen = true;
	}
	closeMenu() {
		this.header?.classList.remove('_menu');
		this.menuTl?.reverse();
		if (this.menuWrap) {
			enableBodyScroll(this.menuWrap);
		}
		this.isMenuOpen = false;
	}
	increaseHeaderCartItems(number) {
		const cartItems = document.querySelector('[data-cart-items]');
		if (cartItems) {
			console.log(cartItems.innerHTML);
			if (!number) {
				cartItems.innerHTML = Number(cartItems.innerHTML) + 1;
				return;
			}
			cartItems.innerHTML = Number(cartItems.innerHTML) + number;
		}
	}
}

export default new Header();
